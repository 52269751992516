<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="공정사고 기본정보" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-show="(tabParam.investigationFlag === 'Y' && (tabParam.accidentStatusCd === 'ISPC000003' || tabParam.accidentStatusCd === 'ISPC000002'))
              && !disabled" 
              :isSubmit="isComplete1"
              :url="completeUrl"
              :param="tabParam"
              :mappingType="completeType"
              label="조사완료" 
              icon="check"
              @beforeAction="completeAccident('ING')"
              @btnCallback="completeCallback" />
            <font v-if="isInvest && tabParam.accidentStatusCd === 'ISPC000002' && (standardInfo.reportTypeCd === 'IRC0000005' || standardInfo.reportTypeCd === 'IRC0000001')" color="#C10015">
              {{$language('※ 종결처리시 상단탭에서 중간, 최종보고를 선택하여 추가를 할 수 있습니다.')}}
            </font>
            <c-btn 
              v-show="isInvest && !disabled && tabParam.accidentStatusCd === 'ISPC000002'" 
              :isSubmit="isComplete2"
              :url="completeUrl"
              :param="tabParam"
              :mappingType="completeType"
              label="종결처리" 
              icon="check"
              @beforeAction="completeAccident('END')"
              @btnCallback="completeCallback" />
            <!-- <c-btn v-if="!disabled" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" /> -->
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="사고번호" :value="standardInfo.accidentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고명" :value="standardInfo.accidentName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고종류" :value="standardInfo.accidentKindName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고등급" :value="standardInfo.accidentGradeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생일시" :value="standardInfo.occurrenceDate"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생부서" :value="standardInfo.occurrenceDeptName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생형태 대분류" :value="standardInfo.occurrenceModeLargeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생형태 중분류" :value="standardInfo.occurrenceModeSmallName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div> 
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabHeight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.key"
            :popupParam="param"
            :tabParam="tabParam"
            @transInfo="transInfo"
            @currentStep="currentStep"
            @isInvestY="isInvestY"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-reception',

  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        count: 0,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      standardInfo: {
        accidentNo: '',
        accidentName: '',
        accidentKindName: '',
        accidentGradeName: '',
        occurrenceDate: '',
        occurrenceDeptName: '',
        occurrenceModeLargeName: '',
        occurrenceModeSmallName: '',
        accidentStatusCd: '',

        reportTypeCd: null,
      },
      tabParam: {
        iimAccidentId: '',
        investigationFlag: '',
        investigationDate: '',
        investigationPlan: '',
        reportDate: '',
        occurrenceCause: '',
        accidentDamageHistory: '',
        emergencyMeasures: '',
        materialLossHistory: '',
        materialLossAmount: '',
        enviromentLeakInformation: '',
        enviromentImpactHistory: '',

        accidentInvestigationTeamModelList: [],
        deleteInvestigationTeamModelList: [],
        accidentVictimModelList: [],
        deleteAccidentVictimModelList: [],
      },
      splitter: 5,
      tab: 'processAccidentReceptionPlan',
      completeUrl: 'transactionConfig.sop.iim.accident.process.investigation.complete.url',
      completeType: 'PUT',
      isComplete2: false,
      isComplete1: false,
      insertUrl: '',
      deleteUrl: '',
      isInvest: false,
    };
  },
  watch: {
    'param.count'() {
      this.getStdInfo();
    }
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 180
      return numHeight + 'px';
    },
    disabled() {
      return this.param.stepCd === 'ISPC000005'
    },
    tabItems() {
      return [
        { name: 'processAccidentReceptionPlan', icon: 'person_outline', label: '조사계획', component: () => import(`${'./processAccidentReceptionPlan.vue'}`), key: uid() },
        { name: 'processAccidentReceptionResult', icon: 'report', label: '조사결과', component: () => import(`${'./processAccidentReceptionResult.vue'}`), key: uid() },
        // { name: 'imprTab', icon: 'add_circle_outline', label: '개선관리', component: () => import(`${'@/pages/sop/ibm/imprTab.vue'}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.investigation.insert.url;
      this.completeUrl = transactionConfig.sop.iim.accident.process.investigation.complete.url;
      // code setting
      // list setting
      this.getStdInfo();
    },
    getStdInfo() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    transInfo(data) {
      this.tabParam = data;
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeAccident(type) {
      let message = ''
      if (type === 'ING') {
        message = '조사완료';
      } else {
        message = '종결처리';
      }
      this.completeUrl = this.completeUrl;
      this.completeType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: message + ' 하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (this.tabParam.investigationDate) {
            this.tabParam.investigationDate = this.tabParam.investigationDate.join();
          }
          if (this.tabParam.accidentVictimModelList) {
            this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
              if (_item.expectTreatmentDate)  _item.expectTreatmentDate = _item.expectTreatmentDate.join();
            })
          }
          if (type === 'ING') {
            this.tabParam.accidentStatusCd = 'ISPC000004'
          } else {
            this.tabParam.accidentStatusCd = 'ISPC000005'
          }
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          if (type === 'ING') {
            this.isComplete1 = !this.isComplete1;
          } else {
            this.isComplete2 = !this.isComplete2;
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.tabParam.investigationDate) {
        this.tabParam.investigationDate = this.tabParam.investigationDate.split(',');
      }
      if (this.tabParam.accidentVictimModelList) {
        this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
          if (_item.expectTreatmentDate) {
            _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
          }
        })
      }
      if (this.tabParam.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.tabParam.accidentStatusCd 
        })
        this.$emit('emitStep', {
          name: 'setReportTab',
          param: { stepCd: this.tabParam.accidentStatusCd,
                  iimAccidnetId: this.param.iimAccidentId  },
        })
      }
    },
    currentStep() {
      if (this.tabParam.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.tabParam.accidentStatusCd 
        })
      }
    },
    isInvestY(data) {
      this.isInvest = data;
    }
  }
};
</script>
